import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";

import { Title, Section, Box, Text, Span } from "../../components/Core";
import { breakpoints } from "../../utils";
import { device } from "../../utils";




const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
  text-align:center;
  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const SectionStyled = styled(Section)`
  background-color: #fff;

  padding-top:5px;
  padding-bottom: 50px;

  @media ${device.md} {
    padding-top:5px;
  
  }
`;


const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
    margin-top: 10px;
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.shadow};
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors.secondary};
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }
`;

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
    bg="#f7f7fb"
    border="1px solid"
    borderColor="border"
    p="30px"
    mx="10px"
    borderRadius={10}
    className={`${className}`}
    {...rest}
    css={`
      &:focus {
        outline: none;
      }
    `}
  >
    <Text color="dark" mb={2}>
      {children}
    </Text>
    <Box className={``}>
   
      <div className="flex-grow-1">
        <Title variant="card">
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
    </Box>
    <Box className="" mr={3}>
        <img src={image} alt="" className="img-fluid" />
      </Box>
  </Box>
);

const RhinoQ = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
   <SectionStyled className="pt-5"   pt={3}>
        <Container
        
        >
          
          <Row className="justify-content-center mt-0">
            <Col>
            <HeroT>HAVE ANY <Span color="primary"> QUESTIONS?</Span></HeroT>
            <a href="tel:02070996650"><button type="button" class="fullbtn">CALL US ON : 020 7099 6650</button></a> <br />
            <a href="https://connect.pabau.com/bookings.php?compid=8607"><button type="button" class="fullbtn2">Book an Apppointment</button> </a>
            </Col>
          </Row>

      

        </Container>
        </SectionStyled>
    </>
  );
};

export default RhinoQ;
